<template>
  <div>
    <div v-if="loading">
      <v-container>
        <v-skeleton-loader
          v-bind="attrs"
          type="table-heading, image, list-item-two-line, card"
        ></v-skeleton-loader>
      </v-container>
    </div>
    <div v-else>
      <v-card rounded="0" flat min-height="100vh">
        <div style="height: 100vh; background-color: #F3F6FB;">
          <v-container>
            <div class="text-left">
              <v-img width="120" contain src="../assets/images/logo.png" />
            </div>
          </v-container>
          <v-container fill-height>
            <v-row no-gutters>
              <v-col cols="12" md="6" class="my-auto">
                <div>
                  <v-chip class="text-center" color="success" outlined>
                    100% loan guarantee
                  </v-chip>
                  <h1 class="text-uppercase">
                    <strong>
                      India's Fastest & Super Affordable Bike Loan
                    </strong>
                  </h1>
                  <div class="text--disabled">
                    Low Interest Rate | Instant Approval | Flexible Tenure
                  </div>
                  <div class="my-10">
                    <ul>
                      <li>
                        Interest Rate as low as 7.9%
                      </li>
                      <li>Upto 42 months of repayment tenure</li>
                      <li>Processing fees as low as 1%</li>
                      <li>Funding upto 95% of the vehicle on-road price.</li>
                    </ul>
                  </div>
                </div>
                <v-card
                  @click="applyLoan()"
                  width="100%"
                  color="primary"
                  dark
                  max-width="500"
                  class="py-2 mx-auto"
                >
                  <v-container class="text-center">
                    <strong>Apply Loan</strong>
                  </v-container>
                </v-card>
                <div
                  @click="goToEmiCalculator"
                  class="primary--text text-center my-4"
                >
                  Calculate EMI
                </div>
              </v-col>
              <v-col cols="12" md="6" class="hidden-md-and-down">
                <v-img
                  contain
                  width="100%"
                  src="../assets/banner-m.svg"
                ></v-img>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-card>

      <v-container class="text-center">
        <v-icon color="#FF5F1F">fas fa-horizontal-rule</v-icon>
        <h2>
          <strong> Bike Loan EMI Calculator</strong>
        </h2>
      </v-container>
      <v-container class="pb-10">
        <loan-calculator :theme="'#4F42D8'" />
      </v-container>

      <v-container class="text-center">
        <h1>Our Lending Partner</h1>
        <v-img
          width="250"
          class="mx-auto"
          contain
          src="../assets/images/logo.jpeg"
        />

        <v-card max-width="700" flat class="mx-auto" color="grey lighten-3">
          <v-container class="my-10">
            <v-row class="">
              <v-col class="my-auto" cols="8">
                <h4 class="my-auto">
                  Ready to start your journey with us?
                </h4>
              </v-col>
              <v-col class="my-auto text-right " cols="2">
                <v-btn color="primary" dark @click="applyLoan">
                  Apply Now
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-container>

      <v-container>
        <h1>
          <strong>How it works?</strong>
        </h1>

        <div class="py-10">
          <div class="d-flex">
            <div class="draw">
              <v-avatar color="primary" size="30">
                <span class="white--text">1</span>
              </v-avatar>
            </div>
            <div class="ml-4 pb-5">
              <h3><strong>Provide Your Basic Information</strong></h3>
              Our team or representatives from Bajaj Auto Finance will promptly
              get in touch with you.
            </div>
          </div>
          <div class="d-flex">
            <div class="draw">
              <v-avatar color="primary" size="30">
                <span class="white--text">2</span>
              </v-avatar>
            </div>
            <div class="ml-4 pb-5">
              <h3>
                <strong>Documentation Assistance</strong>
              </h3>

              We will guide you through the documentation process and assess
              your eligibility. Once you meet the criteria, we will underwrite
              your loan and present you with a suitable offer.
            </div>
          </div>
          <div class="d-flex">
            <div>
              <v-avatar color="primary" size="30">
                <span class="white--text">3</span>
              </v-avatar>
            </div>
            <div class="ml-4 pb-5">
              <h3>
                <strong>Purchase Your Bike</strong>
              </h3>
              If you find our loan terms acceptable, our team will provide
              assistance throughout the purchase process. You'll be able to ride
              home on a brand new Bajaj Motorcycle in no time.
            </div>
          </div>
        </div>
      </v-container>

      <v-container>
        <h1>
          <strong>Why two-wheeler loan?</strong>
        </h1>
        <div class="py-10">
          Owning a Bajaj Motorcycle is easier with Two-Wheeler Loans. Fuel and
          maintenance costs can be as high as the bike's price, but Bajaj Auto
          Finance offers loans for up to 42 months. Pay a small amount over time
          that suits you. Get your dream bike today!

          <h3 class="my-4">Bajaj Auto Finance Bike Loan Eligibility</h3>
          <ul>
            <li>
              <strong>Nationality:</strong> You must be an Indian citizen.
            </li>
            <li>
              <strong>Age:</strong> The minimum age for receiving a Bajaj Auto
              Finance Bike Loan is 18 years, and you can apply until the age of
              65 years.
            </li>
            <li>
              <strong>Occupation:</strong> We accept applications from salaried
              individuals, self-employed individuals, those in agricultural
              employment, and dependents.
            </li>
            <li>
              <strong>Bank Account:</strong> Having a bank account is not
              mandatory.
            </li>
            <li>
              <strong>Income Proof:</strong> With or without income proof, even
              individuals new to credit can apply.
            </li>
          </ul>
        </div>
      </v-container>
      <v-container>
        <h1>
          <strong> Why Ontrack? </strong>
        </h1>
        <div class="py-10">
          Discover the benefits of our New Two Wheeler Loan:
          <ul>
            <li>
              <strong> Independence –</strong>
              Say goodbye to relying on public transportation or others for your
              travel needs. With our loan, you can cruise the streets on the
              bike you've always dreamed of!
            </li>
            <li>
              <strong> Hassle-free process –</strong> Enjoy a seamless
              experience with our quick loan processing and minimal
              documentation requirements. We'll even assist you with RTO
              formalities. Plus, choose from multiple repayment methods that
              suit your convenience. Apply for Ontrack's New Two Wheeler Loan
              today and ride towards a hassle-free journey!
            </li>
          </ul>
        </div>
      </v-container>

      <v-container>
        <h1>
          <strong>Features and Benifits </strong>
        </h1>
        <div class="py-10">
          <div class="d-flex mb-8">
            <div class="mr-2">
              <v-btn fab class="grey lighten-3" icon>
                <v-icon>fab fa-wpforms</v-icon></v-btn
              >
            </div>
            <div>
              <h3><strong>Easy Application</strong></h3>
              <div>
                Ontrack has joined forces with Bajaj Auto Finance to help you
                secure your bike loan. Just click "Apply Now" and fill out the
                simple form. Our team or Bajaj Auto Finance will contact you
                soon to guide you through the application process.
              </div>
            </div>
          </div>

          <div class="d-flex mb-8">
            <div class="mr-2">
              <v-btn fab class="grey lighten-3" icon>
                <v-icon>fab fa-creative-commons-zero</v-icon></v-btn
              >
            </div>
            <div>
              <h3><strong>No Hidden Charges</strong></h3>
              <div>
                We assure you that we are transparent about all the charges
                involved. Our team will clearly communicate all the costs before
                you make a decision, ensuring there are no last-minute
                surprises. We are committed to providing you with a seamless
                bike purchasing experience.
              </div>
            </div>
          </div>

          <div class="d-flex mb-8">
            <div class="mr-2">
              <v-btn fab class="grey lighten-3" icon>
                <v-icon>fas fa-receipt</v-icon></v-btn
              >
            </div>
            <div>
              <h3><strong>Lowest Downpayment</strong></h3>
              <div>
                We understand your concerns about sharing too much information.
                In fact, you can obtain a bike loan from us without the need for
                a bank account or income proof. Even if you have never borrowed
                before, we are more than willing to be your first lender.
              </div>
            </div>
          </div>

          <div class="d-flex mb-8">
            <div class="mr-2">
              <v-btn fab class="grey lighten-3" icon>
                <v-icon>fas fa-chart-pie</v-icon></v-btn
              >
            </div>
            <div>
              <h3><strong>Minimal Processing Fees</strong></h3>
              <div>
                Applying through Ontrack comes with a significant advantage.
                While traditional dealerships typically charge 3-4% of the loan
                amount as processing fees, we offer a much lower rate of only
                1%. And here's even more good news: if you apply for a Bajaj
                Chetak EV, we will waive the processing fees completely, making
                it 0%.
              </div>
            </div>
          </div>
        </div>
      </v-container>

      <v-container>
        <h1>
          <strong>What our customers says</strong>
        </h1>
        <div class="scroll-container py-15">
          <v-col v-for="(data, index) in reviews" :key="index">
            <v-card min-height="350px" min-width="270">
              <v-card-text>
                <p class="ma-0 pa-0 mb-4">
                  <v-icon class="color-p" large> fas fa-quote-left </v-icon>
                </p>
                <p class="para-s">{{ data.feedback }}</p></v-card-text
              >
              <v-card-actions>
                <v-list-item class="grow" style="position:absolute;bottom:0">
                  <v-list-item-avatar color="grey darken-3">
                    <v-img class="elevation-6" alt="" :src="data.image"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      <p class="ma-0 pa-0 subtitle-2">{{ data.name }}</p>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card-actions>
            </v-card>
          </v-col>
        </div>
      </v-container>

      <v-container class="pb-15">
        <v-card
          @click="applyLoan()"
          max-width="600"
          class="py-2 mx-auto"
          width="100%"
          color="primary"
          dark
        >
          <v-container class="text-center">
            <strong>Apply Loan</strong>
          </v-container>
        </v-card>
      </v-container>

      <v-container>
        <h1><strong>FAQ?</strong></h1>
        <div class="py-10">
          <v-expansion-panels>
            <v-expansion-panel v-for="(data, index) in faqsData" :key="index">
              <v-expansion-panel-header>
                <strong>{{ data.heading }}</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-html="data.desc" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-container>
    </div>
    <v-dialog
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      v-model="showLoanForm"
    >
      <v-card>
        <v-container>
          <div class="d-flex justify-space-between">
            <div>
              <h2 class="my-auto">Loan Application</h2>
              <div>Please fill the form below</div>
            </div>
            <v-btn @click="showLoanForm = false" class="my-auto" icon
              ><v-icon>fal fa-times</v-icon></v-btn
            >
          </div>
          <v-divider class="mt-2"></v-divider>
        </v-container>
        <loanForm
          :closeModal="closeModal"
          :utmSource="utmSource"
          :campaign="campaign"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import loanForm from "./loanForm.vue";
import LoanCalculator from "../components/LoanCalculator.vue";
export default {
  components: { LoanCalculator, loanForm },
  data() {
    return {
      showLoanForm: false,
      loading: false,
      reviews: [
        {
          rating: 5.0,
          feedback:
            "The transparency shown by Ontrack during my loan application is commendable, the loan amount approved was instantly show-cased. Even at showrooms they take longer. Interest is reasonable.",
          name: "Trecel",
          image:
            "https://on-track-jarvis.s3.ap-south-1.amazonaws.com/397ee97d-e3ca-4522-ab42-4090a8624e07.png",
        },
        {
          rating: 4.0,
          feedback:
            "I’m very happy with this application, my loan got rejected with a different app, but Ontrack gave me a loan within 5 mins. My first Activa, thank you Ontrack.",
          name: "Mukheer",
          image:
            "https://on-track-jarvis.s3.ap-south-1.amazonaws.com/b1758edc-2d85-43f7-bedf-4b4041082a0b.png",
        },
        {
          rating: 5,
          feedback:
            "Never thought bike loans would be as easy as personal loans, simple form and loan approved. Had few doubts, but their FAQ section is very transparent. Didn’t even have to call.",
          name: "Sowphiya",
          image:
            "https://on-track-jarvis.s3.amazonaws.com/41883619-c132-4a52-a987-8cf362052c3b.png",
        },
      ],
      faqsData: [
        {
          heading: "What is Ontrack's New Two Wheeler Loan?",
          desc:
            "If you are in need of a new vehicle, our two wheeler loan is the way to go. We provide you with the opportunity to have the vehicle that you have always dreamed of driving. Our new two wheeler loan comes with an low interest rate and affordable EMIs to help you take your dream bike home today.",
        },
        {
          heading: `${"Why choose Ontrack's New Two Wheeler loan?"}`,
          desc:
            "<p class='para-s'>Our New Two Wheeler Loan offers the following benefits:</p><p class='para-s'>Independence – You don't have to be dependent on public transport or anyone else for all your transportation needs. You can roam the streets with the vehicle you've always wanted!</p><p class='para-s'>Hassle-free process – We provide quick processing of your loan with minimal documentation. We also provide assistance with RTO formalities and multiple repayment methods as per your convenience. Apply for Ontrack's New two wheeler loan today!</p>",
        },
        {
          heading: "How can I be eligible for Ontrack's Two Wheeler Loan?",
          desc:
            '<v-col><p class="para-s"> Salaried Professional &amp; Self-Employed over the age of 21 years and below 65 years by the end of the tenure. </p><p class="para-s"> Employment Stability of at least 3 months in case of Salaried Professionals &amp; 12 months in case of Self-Employed. </p><ul><li class="para-s"> The applicant’s age must be between 21 to 60 years. </li><li class="para-s"> The applicant must have a residential stability for a minimum of one year. </li><li class="para-s"> The applicant must have a stable employment status for at least a year. </li></ul></v-col>',
        },
        {
          heading: "Documentation",
          desc:
            '<v-col><ul><li class="para-s">Identity Proof documents –</li><p class="para-s"> Passport <br>Driving License <br>PAN Card <br>Aadhaar Card <br>Voter’s ID and <br>Recent passport-sized photographs </p><li class="para-s">Income Proof documents (if required) –</li><p class="para-s"> Latest Income Tax Returns (ITR) for self-employed individuals and <br>Last three months’ Salary Slips/Form 16/Last three months’ Bank Statements reflecting salary credits/Salary Certificate for salaried individuals </p><li class="para-s"> Last three months’ Bank Statements – mandatory </li><li class="para-s">Residence Ownership Proof – optional</li></ul></v-col>',
        },
        {
          heading: "Fee & Charges",
          desc:
            '<p class=""><p class="para-s"><p class="font-weight-bold para-s">Onroad price</p><p class="para-s">Depending on vehicle</p></p><p class="para-s"><p class="para-s font-weight-bold">EMI Tenure</p><p class="para-s">24 - 36 Months</p></p><p class="para-s"><p class="para-s font-weight-bold">Downpayment</p><p class="para-s">10 - 50%</p></p><p class="para-s"><p class="para-s font-weight-bold">Stamp Fees</p><p class="para-s">Rs 100</p></p><p class="para-s"><p class="para-s font-weight-bold">Rate of Interest</p><p class="para-s">11.99%.</p></p><p class="para-s"><p class="para-s font-weight-bold">Processing Fees</p><p class="para-s">3%</p></p><p class="para-s"><p class="para-s font-weight-bold">NACH Fees</p><p class="para-s">Rs 500</p></p></p>',
        },
        {
          heading: "Definition",
          desc:
            '<p class="para-s"><p class="mb-4 para-s"><On Road price: The On-road Price of a vehicle is the price you pay to bring the vehicle from the showroom to the road. </p><p class="mb-4 para-s">Downpayment: A down payment is an initial non-refundable payment that is paid upfront for the purchase of a high-priced item. </p><p class="mb-4 para-s">Resale amount: The price which something can achieve when sold in the future. </p><p class="mb-4 para-s">EMI: EMI or equated monthly installment, as the name suggests, is one part of the equally divided monthly outgoes to clear off an outstanding loan within a stipulated time frame. </p><p class="mb-4 para-s">ROI:Rate of Interest; the proportion of a loan that is charged as interest to the borrower, typically expressed as an annual percentage of the loan outstanding. </p><p class="mb-4 para-s">IRR: Internal rate of return is a method of calculating an investment’s rate of return. The term internal refers to the fact that the calculation excludes external factors, such as the risk-free rate, inflation, the cost of capital, or financial risk. </p><p class="mb-4 para-s">Processing Fees: Payment processing fees are the costs that dealerships and banks incur when processing payments from customers. </p><p class="mb-4 para-s">Nach charges:NACH aims at facilitating electronic Interbank High or Low Volume Debit/Credit transactions which are repetitive in nature. The banks charge a small fee from the customer for this. </p><p class="para-s">Stamp charges: Stamp duty is a tax that is levied on single property purchases or documents. A physical revenue stamp had to be attached to or impressed upon the document to show that stamp duty had been paid before the document is legally effective. </p><p class="para-s">Tenure:This stands for the agreed loan repayment time-frame between the borrower (you) and the lender (bank). </p></p>',
        },
      ],
      utmSource: "organic",
      campaign: "",
    };
  },
  mounted() {
    this.initHomePage();
  },
  methods: {
    closeModal() {
      this.showLoanForm = false;
    },
    goToEmiCalculator() {
      window.scrollTo({
        top: 700,
        left: 100,
        behavior: "smooth",
      });
    },
    applyLoan() {
      let eventName = this.utmSource
        ? "bajajApplyLoan-" + this.utmSource
        : "bajajApplyLoan-organic";

      this.$gtag.event(eventName, {
        event_category: "click-event",
        event_label: "page view",
        value: 1,
      });
      this.showLoanForm = true;
    },
    async initHomePage() {
      var gclId = this.$route.query.gclid || null;
      var fbclId = this.$route.query.fbclid || null;
      var wclId = this.$route.query.wclid || null;
      var source = "web";
      this.utmSource = this.$route.query.utm;
      this.campaign = this.$route.query.campaign;
      var leadSource = null;
      if (gclId) {
        leadSource = "Google";
      }
      if (fbclId) {
        leadSource = "Facebook";
      }
      if (wclId) {
        leadSource = "WhatsApp";
      }
      if (source) {
        leadSource = source;
      }
      if (this.utmSource) {
        leadSource = this.utmSource;
      }
      try {
        var eventName = leadSource
          ? "bajajPageView-" + leadSource
          : "bajajPageView-organic";
        window.fbq("track", eventName);
        this.$gtag.event(eventName, {
          event_category: "click-event",
          event_label: "page view",
          value: 1,
        });
      } catch {
        console.log("Error in source delecation");
      }
    },
  },
};
</script>

<style>
.draw {
  background-image: linear-gradient(#dedede, #dcdcdc);
  background-size: 2px 100%;
  background-repeat: no-repeat;
  background-position: center center;
}
</style>
